body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

 .custom-menu .ant-menu-item {
     color: white !important;
 }

 .custom-menu .ant-menu-horizontal {
     background: rgb(29, 28, 28) !important;
     display: flex;
     justify-content: center;
     flex: 1;
 }

 .custom-menu .ant-menu-item-selected {
     font-weight: bold !important;
 }

 .menu-icon {
    margin-left: 10px;
     font-size: 32px;
     color: white;
    margin-top: 10px;
     cursor: pointer;
 }

 @media screen and (max-width: 480px) {
     .custom-menu {
      
         display: block;
     }

     .menu-icon {
         display: block;
         margin: 0 auto;
         margin-top: 10px;
         text-align: center;
     }

     .ant-menu-horizontal {
         background: rgb(28, 28, 29) !important;
     }
 }

 
.footer {
    
    display: flex;
    padding: 20px;
    background-color: #2980B9;
    color: #fff;
}

.footer-column {
    flex: 1;
    margin: 0 50px;
}

 

.footer-column h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.footer-column p {
    font-size: 14px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 5px;
    font-size: 14px;
}

.footer-column a {
    color: #fff;
    text-decoration: none;
}

.footer-socials {
    display: flex;
    gap: 10px;
}

.footer-socials img {
    width: 20px;
    height: 20px;
}
 
 
 @media screen and (max-width: 768px) {
     .footer-column {
            flex: 1;
                margin: 0 10px;
     }
 }

 @media screen and (max-width: 480px) {
     .footer-column {
            flex: 1;
                margin: 0 40px;
     }
    
 }